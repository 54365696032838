@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Raleway&family=Titillium+Web:wght@700&display=swap');

* {
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
}

html {
  --shield-background: #252C37;
  --border: cyan;
  --skew: -45deg;
  --inverse-skew: 45deg;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}


/* scroll bar thin blue */
::-webkit-scrollbar {
  width: 1px;
  background: transparent;
}

/* scroll bar thin blue */
::-webkit-scrollbar-thumb {
  background: #00ffff;
  border-radius: 10px;
}


.posRel {
  position: relative;
}

.posAbs {
  position: absolute;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: white;
}

section {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5; 
  align-items: center;
}

.sectionPage {
  position: absolute;
  height: 100%; 
  display: flex; 
  flex-direction: column; 
  z-index: 1000; 
  align-items: center;
}

h1 {
  font-family: Titillium Web, sans-serif;
  font-weight: 700;
  font-size: clamp(40px, 5vw, 60px);
  color: #00ffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 3px;
}

h2 {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: clamp(20px, 3vw, 30px);
  color: #ffffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 7px;
}

h3 {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: clamp(18px, 3vw, 24px);
  color: #ffffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 1px;
}

h4 {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: clamp(10px, 2vw, 12px);
  color: #00ffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 3px;
  text-transform: uppercase;
}

p {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: clamp(16px, 3vw, 24px);
  color: #ffffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 1px;
}

p.small {
  font-size: clamp(12px, 3vw, 14px);
}

label {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: clamp(12px, 3vw, 14px);
  color: #00ffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 1px;
}


.nowrap {
  white-space: nowrap;
}

header,
footer {
  position: fixed;
  width: 100vw;
  z-index: 100;
}

header {
  top: 0;
}

footer {
  bottom: 0;
} 

input {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: clamp(16px, 3vw, 24px);
  color: #ffffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 1px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #00ffff;
  outline: none;
  width: 100%;
  padding: 0 5px;
}

select {
  font-family: Nunito, sans-serif;
    font-weight: 400;
    font-size: clamp(16px, 3vw, 24px);
    color: #ffffff;
    text-shadow: 0 0 5px #00ffff;
    margin: 0;
    letter-spacing: 1px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #00ffff;
    outline: none;
    width: 100%;
    padding: 0 5px;
}

select > option {
  font-family: Nunito, sans-serif;
    font-weight: 400;
    font-size: clamp(16px, 3vw, 24px);
    color: #ffffff;
    text-shadow: 0 0 5px #00ffff;
    margin: 0;
    letter-spacing: 1px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #00ffff;
    outline: none;
    width: 100%;
    padding: 0 5px;
}

.w-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.flex.aic {
  align-items: center;
}

.flex.jcc {
  justify-content: center;
}

.flex.jcsb {
  justify-content: space-between;
}

.flex.col {
  flex-direction: column;
}

.flex .grow1 {
  flex-grow: 1;
}

.mt-a {
  margin-top: auto;
}

.ml-a {
  margin-left: auto;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.t-center {
  text-align: center;
}

button {
  text-transform: uppercase;
  font-family: Titillium Web;
  font-size: clamp(13px, 3vw, 18px);
  text-transform: uppercase;
  line-height: 1;
  height: clamp(30px, 10vh, 50px);
  color: #ffffff;
  text-shadow: 0 0 5px #00ffff;
  margin: 0;
  letter-spacing: 1px;
  background: transparent;
  border: 1px solid #00ffff;
  outline: none;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0 10px #00ffff;
  background: #252C3730;
}

button.minWidth {
  width: clamp(125px, 30vw, 160px);
}

.cutCorners {
    width: clamp(125px, 30vw, 160px);
    border: none;
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 60% 80%, 40% 100%, 0% 100%); */
    clip-path: polygon(20px 0, calc(100% - 20px) 0, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0 calc(100% - 20px), 0 20px);
      /* create the cut-corner shape */
      /* polygon(0 0, 100% 0, 100% 100%, 15% 100%, 0% 50%) */
}

.areaTitle {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: clamp(60px, 20vh, 70px);
}

.areaTitle h4 {
  border: 1px solid #00ffff;
  padding: 2px 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-a {
  margin-left: auto;
}

.menuBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: clamp(60px, 10vh, 80px);
  display: flex;
  justify-content: space-between;
  z-index: 200;
  padding: 0 5vw;
}

.fadeIn {
  opacity: 0;
  transition: ease-in all 2s;
  transition-delay: 1s;
}

.fadeOut {
  opacity: 0.1;
  transition: ease-out all 5s;
}

.fadeIn.ready  {
  opacity: 1;
}

.box {
  height: 46vh;
  width: 100%;
  background: var(--shield-background);
  transition: ease-out all 1s;
  transition-delay: 1s;
  overflow: hidden;
}

.title {
  height: 43vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mainButton {
  width: clamp(200px, 30vw, 300px);
  height: clamp(30px, 10vh, 50px);
  background: var(--shield-background);
  border: none;
  border-radius: 5px;
  border: 1px solid #00ffff;
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff;
  font-size: clamp(20px, 3vw, 30px);
  box-shadow: 0 0 10px #00ffff;
  text-transform: uppercase;
  font-family: Titillium Web;
  cursor: pointer;
}

.mainButton.disabled {
  pointer-events: none;
  opacity: 0.5;
}


.ready .box {
  height: 3vh;
}

.curve {
  display: flex;
  width: 100%;
  height: calc(4vh - 1px);
}

.left,
.right {
  width: 50%;
  height: 100%;
  background: var(--shield-background);
  position: relative;
  z-index: 20;
}

.center {
  position: relative;
  width: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-out all 1s;
}

header .left,
header .right {
  border-bottom: 10px solid var(--border);
}

header.ready .left,
header.ready .right {
  border-bottom: 0px solid var(--border);
}


header.ready .center {
  width: 100%;
  border-top: 3px solid var(--border);
}

.left>div:nth-child(1),
.right>div:nth-child(1) {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--shield-background);
}

header.ready .left>div:nth-child(1) {
  transform: skew(var(--skew)) translateX(6%);
  border-right: 3px solid var(--border);
  border-bottom: 3px solid var(--border);
}

header.ready .right>div:nth-child(1) {
  transform: skew(var(--inverse-skew)) translateX(-6.8%);
  border-left: 3px solid var(--border);
  border-bottom: 3px solid var(--border);
}

.unskew a {
  transform: skew(var(--inverse-skew)) translateX(6.8%);
}

footer.ready .left>div:nth-child(1) {
  transform: skew(var(--inverse-skew)) translateX(6%);
  border-right: 3px solid var(--border);
  border-top: 3px solid var(--border);
}

footer.ready .right>div:nth-child(1) {
  transform: skew(var(--skew)) translateX(-6.8%);
  border-left: 3px solid var(--border);
  border-top: 3px solid var(--border);
}

footer.ready .center {
  width: 100%;
  border-bottom: 3px solid var(--border);
}

footer.ready .right>div:nth-child(2) {
  position: absolute;
  width: 100%;
  height: 100%;
}

footer.ready .right>div:nth-child(2)>a {
  padding: 1px 5px;
  position: relative;
  top: 30%;
  margin: 0 4px;
  color: white;
  transition: ease-out 0.5s all;
  transition-delay: 2s;
  opacity: 1 !important;
}

footer.ready .right>div:nth-child(2)>a:hover {
  color: #999;
}

footer .right>div:nth-child(2)>a {
  opacity: 0;
}

header.ready  .box {
  height: clamp(30px, 10vh, 50px);
}

footer.ready .box {
  height: clamp(60px, 10vh, 80px);
      display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
}

footer.ready .mainButton {
  display: none;
}

.subtitles {
  margin-bottom: 5vh;
  padding: 10px 20px;
}

.headerBox {
  height:clamp(30px, 10vh, 50px);
  display: flex;
    align-items: center;
}

.menuContainer {
  width: 100%;
  height: clamp(60px, 10vh, 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cutCornerMenuButton {
  width: clamp(200px, 30vw, 300px);
  margin: 0 15%;
  height: clamp(25px, 10vh, 40px);
  background: #252c376b;
  border: 1px solid #00ffff;
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff;
  font-size: clamp(16px, 3vw, 24px);
  box-shadow: 0 0 10px #00ffff;
  text-transform: uppercase;
  font-family: Titillium Web;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cutCornerMenuButton:active {
  background: #00ffff;
  color: #252c37;
}

.menuButton {
    position: absolute;
    background: #00ffff81;
    color: #252c37;
    border: 1px solid #00dbdb;
    text-shadow: 0 0 10px #00ffff;
    transition: all 0.5s ease;
}

.menuButton.on {
  background: #00ffff;
  z-index: 50;
}

.menuButton.topRight {
  /* top: -30px;
  right: -220px; */
  transform: translate(clamp( -140px, -20vw,-105px), -30px);
}

.menuButton.topLeft {
  /* top: -30px;
  right: 60px; */
  transform: translate(clamp(105px, 20vw, 140px), -30px);
}

.menuButton.bottomRight {
  /* top: 30px;
  right: -220px; */
  transform: translate(clamp(-140px, -20vw, -105px), 30px);

}

.menuButton.bottomLeft {
  /* top: 30px;
  right: 60px; */
  transform: translate(clamp(105px, 20vw, 140px), 30px);
}

.subtitle {
  position: absolute;
  top: clamp(-110px, -5vh, -100px);
  width: clamp(300px, 100vw, 500px);
  text-align: center;
  align-self: baseline;
  max-height: clamp(48px, 6vw, 64px);
  overflow-x: hidden;
  padding: 0 20px;
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  align-items: center;
}

.subtitle .cursor {
  min-width: 20px;
  display: inline-block;
  animation: blink 1s step-end infinite;
  content: '|';
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff;
}

.naviInputContainer {
  display: flex;
  justify-content: space-between;
  width: clamp(300px, 100vw, 500px);
  padding: 0 30px;
}

.naviInputContainer.open {
  justify-content: center;
}

.naviInput {
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff;
  font-size: clamp(30px, 10vw, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.char {
  color: #00ffff;
  transition: color ease-out 0.3s, text-shadow ease-out 0.3s;
  text-shadow: 0 0 4rem #fff;
}

.char.fade-in {
  color: #fff;
  transition: color ease-out 0.3s, text-shadow ease-out 0.3s;
  text-shadow: 0 0 2rem #00ffff;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.naviInput .inputContainer {
  width: 0;
  height: 0;
  overflow: hidden;
  transition: width ease-out 0.3s, height ease-out 0.3s;
}

.naviInputContainer.open .naviInput i {
  width: 0;
  height: 0;
  min-width: 0;
  overflow: hidden;
  transition: width ease-out 0.3s;
}

.naviInputContainer .naviInput i {
  width: clamp(40px, 8vw, 60px);
  min-width: clamp(40px, 8vw, 60px);
  height: 100%;
}

.naviInputContainer .naviInput.open i {
  width: clamp(40px, 8vw, 60px);
  min-width: clamp(40px, 8vw, 60px);
  height: 100%;
  margin-right: 20px;
}

.naviInputContainer.open .naviInput.open .inputContainer {
  width: 100%;
  height: 100%;
  transition: width ease-out 0.3s, height ease-out 0.3s;
}

.sendButton {
  width: clamp(60px, 10vw, 80px);
  height: 100%;
  background: #00ffff;
  color: #252c37;
  border: 1px solid #00dbdb;
  text-shadow: 0 0 10px #00ffff;
  transition: all 0.5s ease;
  padding: 0 10px;
  cursor: pointer;
}

.thinking {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #00bfff;
  margin: 0 20px;
}

.thinking:before,
.thinking:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.thinking:after {
  color: #00ffff;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.glowButton {
  background: #00ffff;
  color: #252c37;
  border: 1px solid #00dbdb;
  text-shadow: 0 0 10px #00ffff;
}

.containerCard {
  background: #00ffff50;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.containerCard.float {
  position: absolute;
  height: 350px;
  width: 300px;
  top: -400px;
  overflow-x: hidden;
}

.agendaContainer {
  overflow-x: hidden;
  max-height: 400px;
  width: 400px;
  padding: 25px 20px;
  padding-top: 0;
}

.planContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff10;
  padding: 20px 30px;
  margin: 25px 0 0 0;
  border: 1px solid #00ffff;
  border-radius: 5px;
}